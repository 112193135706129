import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/options.css";

const Options = ({toggleMenuView }) => {
  const navigate = useNavigate();

  let routes = {
    "home-route": { route: "/home", name: "Home" },
    // "cart-route": { route: "/cart", name: "Cart" },
    // "billing-route": { route: "/billing", name: "Billing" },
    // "active-orders-route": { route: "/activeorders", name: "Active Orders" },
    "logout-route": { route: "/logout", name: "Logout" },
  };

  // Effect to toggle body overflow
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto"; // Revert when the component unmounts
    };
  }, []);

  return (
    <>
      <div className="menu-window">
        <div className="menu-window-logo">Logo</div>
        <div className="menu-window-items">
          {Object.keys(routes).map((key) => (
            <div
              className="menu-window-item"
              key={key}
              onClick={() => {
                navigate(routes[key].route);
                toggleMenuView();
              }}
            >
              {routes[key].name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Options;

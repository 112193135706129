import { Menu } from "antd";
import { DashboardOutlined, SettingOutlined } from "@ant-design/icons";

const Sidebar = () => (
  <Menu
    mode="inline"
    defaultSelectedKeys={["dashboard"]}
    style={{ width: "100%", height: "100%", borderRight: 0 }}
  >
    <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
      Dashboard
    </Menu.Item>
    <Menu.Item key="manage-dishes" icon={<SettingOutlined />}>
      Manage Dishes
    </Menu.Item>
    {/* Add more items here */}
  </Menu>
);

export default Sidebar;

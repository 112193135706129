// src/components/DozoPiece.js
import React from 'react';

const DozoPiece = ({ onClick, color, position }) => {
    const style = {
        width: '30px',  // Diameter of the dot
        height: '30px',
        backgroundColor: color || '#ddd', // Default color if none is assigned
        border: '2px solid #666', // Border to make the dots more visible
        borderRadius: '50%', // Circular shape
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        transition: 'background-color 0.3s ease' // Smooth transition for color change
    };

    return <div style={style} onClick={onClick} />;
};

export default DozoPiece;

import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { login } from './Auth';
import '../Styles/login.css';

const Login = () => {
  const [phone_number, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/home';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(phone_number, password);
      if (response.token) {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <div className="loginpage">
      <div className="logo">
        <p>Logo</p>
      </div>
      <div className="login">
        <form className="loginform" onSubmit={handleSubmit}>
          <input
            type="text"
            id="phone_number"
            autoComplete="off"
            placeholder="Phone Number"
            value={phone_number}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            type="password"
            id="password"
            autoComplete="off"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
        <div className="forgotpassword">
          Forgot password? Click here to recover
        </div>
        <div className="createnewaccount">
          <Link to="/register">
            <p>Don't have an account? Sign Up</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;

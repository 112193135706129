import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../Authentication/AxiosInstance";
import "../Styles/register.css";

const Register = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await postRequest(
        "/user/register",
        {
          first_name,
          last_name,
          email,
          phone_number,
          password,
        },
        false // Assuming registration doesn't require authentication
      );

      navigate("/login");
      console.log("Response: ", response);
    } catch (error) {
      console.log("Registration Failed: ", error);
    }
  };

  return (
    <div className="register-page">
      <div className="logo">
        <p>Logo</p>
      </div>
      <div className="register">
        <form className="register-form" onSubmit={handleRegistrationSubmit}>
          <input
            type="text"
            id="first_name"
            autoComplete="off"
            placeholder="First Name"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            id="last_name"
            autoComplete="off"
            placeholder="Last Name"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            type="email"
            id="email"
            autoComplete="off"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            id="phone_number"
            autoComplete="off"
            placeholder="Phone Number"
            value={phone_number}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <input
            type="password"
            id="password"
            autoComplete="off"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Sign Up</button>
        </form>
        <div className="forgot-password">
          Forgot password? Click here to recover
        </div>
        <div className="signintoexisting">
          <Link to="/login">
            <p>Already have an account? Sign In</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;

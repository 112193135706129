import ClickButton from "../Function/ClickButton.jsx";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const TestClickButton = () => {
  const navigate = useNavigate(); // Call useNavigate inside the component

  const handleClick = async (e) => {
    navigate("/activeorders");  // Use the navigate function here
  };

  return (
    <div className="">
      <ClickButton name="Button" handleClick={() => {}} />
    </div>
  );
};

export default TestClickButton;

import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/cartbutton.css"; // Your custom styles for the button
import cartIcon from "../../../Images/heart.png"; // Adjust the path to your cart icon
import { useCart } from "../../Orders/CartContext";
import { WishlistContext } from "../../Orders/Wishlist/WishlistContext";

const CartButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {wishlist} = useContext(WishlistContext);
  const [itemCount, setItemCount] = useState(0);

  useEffect(() => {
    const updateItemCount = () => {
      const count = Object.values(wishlist).length;
      setItemCount(count);
    };

    updateItemCount();
  }, [wishlist]);

  const showCartButton = () => {
    return (
      location.pathname === "/restaurant" ||
      location.pathname === "/activeorders"
    );
  };

  const handleCartClick = () => {
    navigate("/wishlist");
  };

  if (!showCartButton()) {
    return null;
  }

  return (
    <div className="cart-button" onClick={handleCartClick}>
      <div className="cart-icon">
        <img src={cartIcon} alt="Cart" />
      </div>
      {itemCount > 0 && <div className="item-count">{itemCount}</div>}
    </div>
  );
};

export default CartButton;

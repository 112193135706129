import React, { createContext, useRef, useState, useEffect } from "react";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../Utilities/LocalStorage/localStorageUtils";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../Authentication/AxiosInstance";

export const RestaurantContext = createContext();

const fetchMenu = async (restaurantId) => {
  try {
    const response = await getRequest(`/user/menu`, {
      restaurant_id: restaurantId,
    });
    return response?.menu || {};
  } catch (error) {
    console.error("Error fetching menu:", error);
    return {};
  }
};

export const RestaurantProvider = ({ children }) => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [restaurantDetails, setRestaurantDetails] = useState(
    () =>
      readFromLocalStorage("restaurantDetails") || {
        id: null,
        name: "",
        address: "",
        image: "",
      }
  );
  const [restaurantMenu, setRestaurantMenu] = useState([]);

  const scrollToMenu = () => {
    if (window.location.pathname !== "/restaurant") {
      navigate("/restaurant", { state: { scrollToMenu: true } });
    } else {
      console.log("AAAAA", menuRef);
      menuRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (restaurantDetails.id) {
      const fetchRestaurantMenu = async () => {
        const menu = await fetchMenu(restaurantDetails.id);
        setRestaurantMenu(menu);
      };
      fetchRestaurantMenu();
    }
  }, [restaurantDetails.id]);

  useEffect(() => {
    writeToLocalStorage("restaurantDetails", restaurantDetails);
  }, [restaurantDetails]);

  return (
    <RestaurantContext.Provider
      value={{
        scrollToMenu,
        menuRef,
        restaurantDetails,
        setRestaurantDetails,
        restaurantMenu,
        setRestaurantMenu,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

import React, { useState } from "react";
import "./questionflow.css";
import ClickButton from "../../Utilities/Function/ClickButton";

const QuestionFlow = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});

  const questions = [
    {
      id: "1",
      question: "What type of drink are you in the mood for?",
      options: ["Coffee", "Tea"],
    },
    {
      id: "2",
      question: "Do you prefer something hot or cold?",
      options: ["Hot", "Cold"],
    },
    {
      id: "3",
      question: "Do you like sweet or bitter flavors?",
      options: ["Sweet", "Bitter"],
    },
    {
      id: "4",
      question: "Any specific ingredients you want?",
      options: ["Milk", "No Milk"],
    },
  ];

  const handleAnswer = (option) => {
    setAnswers((prev) => ({
      ...prev,
      [questions[currentQuestion].id]: option,
    }));
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // Suggest a drink based on the answers
      // Suggestion logic can be implemented here
      console.log("Answers:", answers);
    }
  };

  return (
    <div className="question-flow-container">
      <div className="question-flow-card">
        <div className="question-text">
          {questions[currentQuestion].question}
        </div>
        <div className="question-options">
          {questions[currentQuestion].options.map((option, index) => (
            <ClickButton
              name={option}
              handleClick={() => handleAnswer(option)}
              height="50px"
              width="100%"
              backgroundColor="var(--Foundation-Orange-orange-100)"
              fontSize="16px"
              fontColor="var(--Foundation-Orange-orange-700)"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionFlow;

import React, { useEffect, useState } from "react";
import "./button.css";

const QuantityButton = ({ 
  identifier, 
  quantity, 
  handleChange, 
  height = 20, 
  width = 60, 
  borderRadius = 5, 
  fontSize = 12 
}) => {
  const [qnt, setQnt] = useState(() => {
    if (quantity !== undefined) {
      return quantity;
    } else {
      return 0;
    }
  });

  const decrementQnt = () => {
    if (qnt > 0) {
      setQnt(qnt - 1);
    }
  };

  const incrementQnt = () => {
    setQnt(qnt + 1);
  };

  useEffect(() => {
    if (handleChange !== undefined && identifier !== undefined) {
      handleChange(identifier, qnt);
    }
  }, [qnt]);

  return (
    <div
      className="quantity-buttons-holder"
      style={{ 
        height: `${height}px`, 
        width: `${width}px`, 
        borderRadius: `${borderRadius}px`,
        fontSize: `${fontSize}px`
      }}
    >
      <div className="minus" onClick={decrementQnt}>
        -
      </div>
      <div className="quantity">{qnt}</div>
      <div className="plus" onClick={incrementQnt}>
        +
      </div>
    </div>
  );
};

export default QuantityButton;

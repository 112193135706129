import React from 'react';
import "../Styles/clickbutton.css";

function ClickButton({
  name,
  icon, 
  handleClick = () => {},
  iconWidth = '25px',
  iconHeight = '25px',
  height = '30px',
  width = '80px',
  borderRadius = '10px',
  fontSize = '12px',
  fontColor = "var(--Foundation-Orange-orange-50)",
  backgroundColor = "var(--Foundation-Orange-orange-500)"
}) {
  const handleClickDiv = () => {
    handleClick(); // Call the provided onClick handler
  };

  return (
    <div
      className="click-button"
      onClick={handleClickDiv}
      style={{
        height,
        width,
        backgroundColor: backgroundColor,
        borderRadius,
        fontSize,
        color: fontColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease'
      }}
    >
      {icon && <img src={icon} alt={name} style={{ 
        width: iconWidth,
        height: iconHeight,
        marginRight: name ? '10px' : '0' 
        }} />}
      {name}
    </div>
  );
}

export default ClickButton;

import axios from 'axios';
import Cookies from 'js-cookie';

// Create an Axios instance
const api = axios.create({
  baseURL: 'http://34.93.26.57/api', // Base URL for your API
  withCredentials: true, // To send cookies with every request
});

const noAuthApi = axios.create({
  baseURL: 'http://34.93.26.57/api', // Base URL for your API
  withCredentials: false, // To send cookies with every request
});

// Request interceptor to add access token to the request headers
api.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get('access');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to refresh tokens
const refreshToken = async () => {
  try {
    const response = await axios.post('http://34.93.26.57/api/token/refresh', {
      refresh: Cookies.get('refresh'),
    });
    const { access, refresh } = response.data;
    Cookies.set('access', access);
    Cookies.set('refresh', refresh);
    return access;
  } catch (error) {
    console.error('Failed to refresh token', error);
    throw error;
  }
};

// Utility functions for making GET and POST requests
const getRequest = async (url, params = {}, withAuth = true) => {
  try {
    const response = await (withAuth ? api : noAuthApi).get(url, { params });
    return response.data;
  } catch (error) {
    console.error('GET request failed', error);
    throw error;
  }
};

const postRequest = async (url, data, withAuth = false) => {
  try {
    const response = await (withAuth ? api : noAuthApi).post(url, data);
    return response.data;
  } catch (error) {
    console.error('POST request failed', error);
    throw error;
  }
};

const putRequest = async (url, data, withAuth = false) => {
  try {
    const response = await (withAuth ? api : noAuthApi).put(url, data);
    return response.data;
  } catch (error) {
    console.error('PUT request failed', error);
    throw error;
  }
};

// Response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Check if the error is due to an expired token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
export { getRequest, postRequest, putRequest };

import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/restaurant.css";
import Menu from "./Menu";
import Table from "./Table";
import TableNavigationView from "../TableNavigationView/TableNavigationView";
import Navbar from "../NavigationBar/NavigationBar";
import map_icon from "../Assets/map_icon.png";
import { RestaurantContext } from "./RestaurantContext";

const Restaurant = () => {
  const { scrollToMenu, menuRef, restaurantDetails } =
    useContext(RestaurantContext);
  const [selectedHeader, setSelectedHeader] = useState("menu");
  const [tableNavigation, setTableNavigation] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToMenu) {
      scrollToMenu();
    }
  }, [location, scrollToMenu]);

  const toggleTableNavigationView = () => {
    setTableNavigation(!tableNavigation);
  };

  const renderSelectedHeaderData = () => {
    if (selectedHeader === "menu") {
      return (
        <div className="menu-data" ref={menuRef}>
          <Menu restaurantId={restaurantDetails.id} />
        </div>
      );
    } else if (selectedHeader === "table") {
      return (
        <div className="table-data">
          <Table restaurantId={restaurantDetails.id} />
        </div>
      );
    }
    return null;
  };

  const [renderStickyHeader, setRenderStickyHeader] = useState(false);
  const handleScroll = () => {
    const headerElement = document.querySelector(".restaurant-page > .info");
    if (headerElement) {
      const { bottom } = headerElement.getBoundingClientRect();
      setRenderStickyHeader(bottom <= 10);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="restaurant-page"
      style={{
        backgroundImage: 
        `linear-gradient(
        0deg, 
        rgba(0, 0, 0, 1) 0%, 
        rgba(0, 0, 0, 0) 100%), url(${
          restaurantDetails.image || "../../Images/default.jpeg"
        })`,
        overflow: tableNavigation ? "hidden" : "",
      }}
    >
      {tableNavigation && (
        <TableNavigationView toggleNavView={toggleTableNavigationView} />
      )}
      <div className="info">
        <div className="address">{restaurantDetails.address}</div>
        <div className="location" onClick={toggleTableNavigationView}>
          <img src={map_icon} className="map-icon" alt="location icon" />
          &nbsp;{restaurantDetails.name}
        </div>
        <div className="welcome">Welcome to</div>
      </div>
      <div className="options">
        <div className="headers">
          <div
            className={`header ${selectedHeader === "menu" ? "selected" : ""}`}
            id="menu"
            onClick={() => setSelectedHeader("menu")}
          >
          </div>
        </div>
        {renderStickyHeader && (
          <div className="restaurant-page-navbar-holder">
            <Navbar
              className="restaurant-page-navbar"
              showRestaurantName={true}
            />
          </div>
        )}
        {renderSelectedHeaderData()}
      </div>
    </div>
  );
};

export default Restaurant;

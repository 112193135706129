import React from "react";
import "./Components/Styles/App.css";
import "antd/dist/reset.css"; // For Ant Design v5

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Authentication/Login";
import Register from "./Components/Authentication/Register";
import Home from "./Components/Homepage/Home";
import Restaurant from "./Components/Restaurantpage/Restaurant";
import Billing from "./Components/Orders/Billing";
import Cart from "./Components/Orders/Cart";
import ActiveOrders from "./Components/Orders/ActiveOrders";
import PrivateRoute from "./Components/Authentication/PrivateRoute";
import TestClickButton from "./Components/Utilities/Test/TestClickButton";
import Navbar from "./Components/NavigationBar/NavigationBar";
import CartButton from "./Components/Utilities/Function/CartButton";
import { CartProvider } from "./Components/Orders/CartContext";
import Logout from "./Components/Authentication/Logout";
import ThemeProvider from "./Components/Utilities/Themes/ThemeProvider";
import { RestaurantProvider } from "./Components/Restaurantpage/RestaurantContext";
import QuestionFlow from "./Components/Trivia/Recommendation/QuestionFlow";
import DozoBoard from "./Components/Trivia/DozoBoard/DozoBoard";
import WordleGame from "./Components/Trivia/DozoBoard/Wordle";
import WishList from "./Components/Orders/Wishlist/Wishlist";
import { WishlistProvider } from "./Components/Orders/Wishlist/WishlistContext";
import RestaurantAdminPanel from "./Components/RestaurantAdmin/RestaurantAdminPanel";
import LoginSignupLayout from "./Components/RestaurantAdmin/Authentication/LoginSignup";
import ForgotPassword from "./Components/RestaurantAdmin/Authentication/ForgotPassword";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/restaurant/login"
          element={<LoginSignupLayout isLogin={true} />}
        />
        <Route
          path="/restaurant/register"
          element={<LoginSignupLayout isLogin={false} />}
        />
        <Route
          path="/restaurant/forgot-password"
          element={<ForgotPassword />}
        />
        <Route path="/restaurant/admin/*" element={<RestaurantAdminPanel />} />
        <Route
          path="/*"
          element={
            <CartProvider>
              <WishlistProvider>
                <RestaurantProvider>
                  <div className="app-main">
                    <Navbar />
                    <CartButton />
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/logout"
                        element={
                          <PrivateRoute>
                            <Logout />
                          </PrivateRoute>
                        }
                      />
                      <Route path="/register" element={<Register />} />
                      <Route
                        path="/home"
                        element={
                          <PrivateRoute>
                            <Home />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/restaurant"
                        element={
                          <PrivateRoute>
                            <ThemeProvider>
                              <Restaurant />
                            </ThemeProvider>
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/questionflow"
                        element={
                          <PrivateRoute>
                            <ThemeProvider>
                              <QuestionFlow />
                            </ThemeProvider>
                          </PrivateRoute>
                        }
                      />
                      <Route path="/billing" element={<Billing />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route
                        path="/activeorders"
                        element={
                          <PrivateRoute>
                            <ActiveOrders />
                          </PrivateRoute>
                        }
                      />
                      <Route path="/" element={<Navigate to="/login" />} />
                      <Route
                        path="/testclickbutton"
                        element={<TestClickButton />}
                      />
                      <Route path="/dozo" element={<DozoBoard />} />
                      <Route path="/wordle" element={<WordleGame />} />
                      <Route path="/wishlist" element={<WishList />} />
                    </Routes>
                  </div>
                </RestaurantProvider>
              </WishlistProvider>
            </CartProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

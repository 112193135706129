import api from "./AxiosInstance";
import Cookies from "js-cookie";

// Function to handle user login
const login = async (phone_number, password) => {
  try {
    const response = await api.post("/user/login", null, {
      params: { phone_number, password },
    });
    console.log("!!!!!!!", response);
    const { access, refresh } = response.data.token;
    Cookies.set("access", access);
    Cookies.set("refresh", refresh);
    return response.data;
  } catch (error) {
    console.error("Login failed", error);
    throw error;
  }
};

// Function to handle user logout
const logout = async () => {
  try {
    const refresh = Cookies.get("refresh");
    const response = await api.post("/user/logout", null, {
      params: { refresh },
    });
    console.log("response", response);
    Cookies.remove("access");
    Cookies.remove("refresh");
  } catch (error) {
    console.error("Logout failed", error);
  }
};

// Function to check if user is authenticated
const isAuthenticated = () => {
  return !!Cookies.get("access");
};

export { login, logout, isAuthenticated };

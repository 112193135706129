import React, { createContext, useContext, useState, useEffect } from "react";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../Utilities/LocalStorage/localStorageUtils";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    return readFromLocalStorage("cart") || {};
  });

  useEffect(() => {
    writeToLocalStorage("cart", cart);
  }, [cart]);

  const addToCart = (item) => {
    setCart((prevCart) => {
      const updatedItem = {
        ...item,
        units: item.units,
      };
      return { ...prevCart, [item.fooditem_id]: updatedItem };
    });
  };

  const updateCartItem = (fooditem_id, units) => {
    setCart((prevCart) => {
      if (units === 0) {
        const { [fooditem_id]: _, ...rest } = prevCart;
        return rest;
      } else {
        return {
          ...prevCart,
          [fooditem_id]: {
            ...prevCart[fooditem_id],
            units,
          },
        };
      }
    });
  };

  const resetCart = () => {
    setCart({});
    writeToLocalStorage("cart", {});
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, updateCartItem, resetCart}}>
      {children}
    </CartContext.Provider>
  );
};

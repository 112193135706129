import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { postRequest } from "../../Authentication/AxiosInstance";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await postRequest("/restaurant/reset-password", {
        phone_number: values.phone_number,
        new_password: values.new_password,
      });
      notification.success({
        message: "Password reset successful",
        description: "You can now log in with your new password.",
      });
      navigate("/restaurant/login"); // Navigate back to login page after reset
    } catch (error) {
      setError("Failed to reset password, please try again.");
    }
    setLoading(false);
  };

  return (
    <div className="auth-fullscreen-container">
      <div className="auth-card">
        <h2>Reset Password</h2>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              { required: true, message: "Please enter your phone number!" },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="New Password"
            rules={[
              { required: true, message: "Please enter your new password!" },
            ]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            Reset Password
          </Button>

          {error && <p className="auth-error">{error}</p>}
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useState, useContext, useEffect } from "react";
import { useCart } from "../Orders/CartContext";
import { RestaurantContext } from "./RestaurantContext";
import { useSpeechSynthesis } from "react-speech-kit";
import { WishlistContext } from "../Orders/Wishlist/WishlistContext";
import ItemCard from "./ItemCard"; // Import the new ItemCard component
import "../Styles/menu.css";

const initializeOrderQuantity = (restaurantMenu, cart) => {
  if (!restaurantMenu) {
    return {};
  }

  let initialOrderQuantity = {};

  restaurantMenu.forEach((item) => {
    let fooditem_id = item.id;
    if (cart && cart[fooditem_id]) {
      initialOrderQuantity[fooditem_id] = cart[fooditem_id]["units"];
    } else {
      initialOrderQuantity[fooditem_id] = 0;
    }
  });

  return initialOrderQuantity;
};

const Menu = () => {
  const { cart, updateCartItem } = useCart();
  const { restaurantMenu } = useContext(RestaurantContext);
  const { speak, voices } = useSpeechSynthesis();
  const selectedVoice = voices.find((voice) => voice.lang.includes("en-IN"));
  const { wishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext);
  const [categorizedMenu, setCategorizedMenu] = useState({});
  const [orderQuantity, setOrderQuantity] = useState(() => {
    let initialOrderQuantity = initializeOrderQuantity(restaurantMenu, cart);
    return initialOrderQuantity;
  });
  const [showTagName, setShowTagName] = useState({});

  useEffect(() => {
    const categories = restaurantMenu.reduce((acc, item) => {
      const { fooditem_category, ...rest } = item;
      if (!acc[fooditem_category]) acc[fooditem_category] = [];
      acc[fooditem_category].push(rest);
      return acc;
    }, {});
    setCategorizedMenu(categories);
  }, [restaurantMenu]);

  useEffect(() => {
    let initialOrderQuantity = initializeOrderQuantity(restaurantMenu, cart);
    setOrderQuantity(initialOrderQuantity);
  }, [restaurantMenu, cart]);

  const handleToggleWishlist = (item) => {
    if (wishlist[item.id]) {
      removeFromWishlist(item.id);
    } else {
      addToWishlist(item);
    }
  };

  const toggleTagDisplay = (itemId, tag) => {
    const key = `${itemId}-${tag}`;
    setShowTagName((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  if (!categorizedMenu || Object.keys(categorizedMenu).length === 0)
    return <div>Loading...</div>;

  return (
    <div className="category">
      {Object.keys(categorizedMenu).map((category_name, index) => (
        <div key={index}>
          <div className="categoryholder">
            <div className="categoryname">{category_name}</div>
            <div className="categoryline">
              <span></span>
            </div>
          </div>
          {categorizedMenu[category_name].map((item, idx) => (
            <ItemCard
              key={idx}
              item={item}
              speak={speak}
              selectedVoice={selectedVoice}
              handleToggleWishlist={handleToggleWishlist}
              toggleTagDisplay={toggleTagDisplay}
              showTagName={showTagName}
              wishlist={wishlist}
              index={idx}
              isLastItem={idx === categorizedMenu[category_name].length - 1} // Pass isLastItem prop
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Menu;

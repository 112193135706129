import React, { useEffect, useState } from "react";
import { postRequest } from "../Authentication/AxiosInstance";
import "../Styles/cart.css";
import img1 from "../../Images/pasta.jpg";
import ClickButton from "../Utilities/Function/ClickButton";
import QuantityButton from "../Utilities/QuantityButton";
import { useNavigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "../Authentication/Auth";
import { useCart } from "./CartContext";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../Utilities/LocalStorage/localStorageUtils";

const Cart = () => {
  const { cart, updateCartItem, resetCart } = useCart();
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleQuantityChange = (id, quantity) => {
    updateCartItem(id, quantity);
  };

  const handlePlaceOrder = async () => {
    if (!isAuthenticated()) {
      navigate("/login", { state: { from: location } });
      return;
    }

    try {
      console.log("CART: ", cart);
      const response = await postRequest("/restaurant/neworder", { cart });

      console.log("RESPONSE: ", response);

      if (response && response.status === 200) {
        console.log("ACTIVE ORDERS:", response.active_orders);
        writeToLocalStorage("active_orders", response.active_orders);
        resetCart();
        navigate("/activeorders");
      } else {
        console.error("Error placing order:", response);
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  useEffect(() => {
    setTotalAmount(
      Math.round(
        Object.values(cart).reduce(
          (acc, item) => acc + item.fooditem_price * item.units,
          0
        )
      )
    );
  }, [cart]);

  return (
    <div className="cartpage">
      {Object.keys(cart).length === 0 ? (
        <div className="empty-cart-message">
          <p>Oops!</p>
          <p>Your cart is empty.</p>
        </div>
      ) : (
        <div className="cart">
          {Object.keys(cart).map((id) => {
            let item = cart[id];
            return (
              <div key={id} className="itemcard">
                <div className="category">{item.fooditem_category}</div>
                <div className="detail">
                  <img src={img1} alt={item.fooditem_name} />
                  <div className="veg"> </div>
                  <div className="info">
                    <div className="item">
                      <div className="name">{item.fooditem_name}</div>
                      <div className="itemquantity-holder">
                        <QuantityButton
                          identifier={id}
                          quantity={item.units}
                          handleChange={handleQuantityChange}
                        />
                      </div>
                    </div>
                    <div className="amount">
                      <div className="unitamount">
                        Rs. {item.fooditem_price}
                      </div>
                      <div className="totalamount">
                        Rs. {Math.round(item.fooditem_price * item.units)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="order">
            <hr />
            <div className="total-amount">Rs. {totalAmount}</div>
            <ClickButton
              name="Order"
              handleClick={handlePlaceOrder}
              className="button"
            />
          </div>
        </div>
      )}
      <div className="suggestions"></div>
    </div>
  );
};

export default Cart;

import React, { useEffect, useState } from "react";
import QrReader from "../Utilities/QrReader";
import { postRequest } from "../Authentication/AxiosInstance";  // Use centralized axios instance for API calls
import qr from "../../Images/qr-code.png";
import ClickButton from "../Utilities/Function/ClickButton";

const Table = ({ restaurantId }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [inputTableId, setInputTableId] = useState('');
  const [tableId, setTableId] = useState(null);

  const onSuccessScan = (data) => {
    setInputTableId(data);
    setShowScanner(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postRequest("/restaurant/jointable", {
        restaurant_id: restaurantId,
        table_number: inputTableId
      });
      setTableId(response.table);
    } catch (error) {
      console.error("Joining table failed", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    if (inputTableId) {
      handleSubmit(new Event('init-event'));
    }
  }, [inputTableId]);  // Ensure inputTableId is included in dependencies

  return (
    <form className="table-form" onSubmit={handleSubmit}>
      <div className="qr-camera-holder">
        {showScanner && <QrReader successScanCallback={onSuccessScan} />}
      </div>
      <input
        type="text"
        id="table_id"
        value={inputTableId}
        autoComplete="off"
        placeholder="Table id"
        onChange={(e) => setInputTableId(e.target.value)}
        onClick={() => setInputTableId('')}
      />
      <div className="buttons">
        <ClickButton
          name="Join"
          handleClick={() => {}}
          height="60px"
          width="70%"
          borderRadius="20px"
          fontSize="18px"
        />
        <div className="qr-button" onClick={() => setShowScanner(true)}>
          <img src={qr} alt="QR" />
        </div>
      </div>
      {tableId && (
        <div className="table-info">
          Awesome! <br />
          You are already dining at <br />
          <br />
          <h1>Table #{tableId}</h1>
        </div>
      )}
    </form>
  );
};

export default Table;

import React, { createContext, useContext, useState, useEffect } from "react";
import { readFromLocalStorage, writeToLocalStorage } from "../../Utilities/LocalStorage/localStorageUtils";

export const WishlistContext = createContext();

export const useWishlist = () => {
  return useContext(WishlistContext);
};

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState(() => {
    return readFromLocalStorage("wishlist") || {};
  });

  useEffect(() => {
    writeToLocalStorage("wishlist", wishlist);
  }, [wishlist]);

  const addToWishlist = (item) => {
    console.log("ITEM: ", item.id, item);
    setWishlist(prevWishlist => {
      const isItemAlreadyAdded = !!prevWishlist[item.id];
      if (isItemAlreadyAdded) {
        console.log(`Item with id ${item.id} already in wishlist.`);
        return prevWishlist;
      }
      const updatedWishlist = {
        ...prevWishlist,
        [item.id]: item
      };
      return updatedWishlist;
    });
  };

  const removeFromWishlist = (fooditem_id) => {
    setWishlist((prevWishlist) => {
      const {[fooditem_id]: _, ...rest} = prevWishlist;
      return rest;
    });
  };

  const resetWishlist = () => {
    setWishlist({});
  };

  return (
    <WishlistContext.Provider value={{ wishlist, addToWishlist, removeFromWishlist, resetWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

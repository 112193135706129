import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "./Auth";
import "../Styles/login.css";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate("/login");
  }, []);

  return null;
};

export default Logout;

import React, { useState, useEffect } from 'react';
import DozoPiece from './DozoPiece';

// Generates positions for a triangular grid setup
const boardCoordinates = (() => {
    let coordinates = [];
    let startX = 200; // Starting X-coordinate
    let startY = 20;  // Starting Y-coordinate
    let verticalSpacing = 25 * Math.sqrt(3); // Spacing between dots horizontally
    let horizontalSpacing = 50; // Vertical spacing between rows

    for (let row = 1; row <= 7; row++) {
        for (let col = 0; col < row; col++) {
            coordinates.push({
                x: startX - (row - 1) * horizontalSpacing / 2 + col * horizontalSpacing,
                y: startY + (row - 1) * verticalSpacing
            });
        }
    }
    return coordinates;
})();

const createInitialBoard = () => Array(28).fill(null); // Initialize board with 28 null spaces

const DozoBoard = () => {
    const [board, setBoard] = useState(createInitialBoard());
    const [currentPlayer, setCurrentPlayer] = useState('red');
    const [winningTriangle, setWinningTriangle] = useState([]);


    const checkForEquilateral = () => {
        const playerPieces = board
            .map((color, idx) => ({ color, idx }))
            .filter(piece => piece.color === currentPlayer)
            .map(piece => boardCoordinates[piece.idx]);

        console.log(playerPieces);
        for (let i = 0; i < playerPieces.length; i++) {
            for (let j = i + 1; j < playerPieces.length; j++) {
                for (let k = j + 1; k < playerPieces.length; k++) {
                    if (isEquilateral(playerPieces[i], playerPieces[j], playerPieces[k])) {
                        console.log("I, J, K: ", i, j, k);
                        setWinningTriangle([playerPieces[i], playerPieces[j], playerPieces[k]]);
                        return currentPlayer;
                    }
                }
            }
        }
        return null;
    };

    const renderWinningLine = () => {
        if (winningTriangle.length === 3) {
            return (
                <svg style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%' 
                    }}>
                    <line x1={winningTriangle[0].x + 15} y1={winningTriangle[0].y + 15} x2={winningTriangle[1].x + 15} y2={winningTriangle[1].y + 15} stroke={currentPlayer} strokeWidth="2" />
                    <line x1={winningTriangle[1].x + 15} y1={winningTriangle[1].y + 15} x2={winningTriangle[2].x + 15} y2={winningTriangle[2].y + 15} stroke={currentPlayer} strokeWidth="2" />
                    <line x1={winningTriangle[2].x + 15} y1={winningTriangle[2].y + 15} x2={winningTriangle[0].x + 15} y2={winningTriangle[0].y + 15} stroke={currentPlayer} strokeWidth="2" />
                </svg>
            );
        }
        return null;
    };
    

    const isEquilateral = (p1, p2, p3) => {
        const d1 = Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
        const d2 = Math.pow(p2.x - p3.x, 2) + Math.pow(p2.y - p3.y, 2);
        const d3 = Math.pow(p3.x - p1.x, 2) + Math.pow(p3.y - p1.y, 2);
        console.log(d1, d2, d3);
        return Math.round(d1) === Math.round(d2) && Math.round(d2) === Math.round(d3);
    };

    useEffect(() => {
        const winner = checkForEquilateral();
        if (winner) {
            alert(`${winner} wins!`);
        } else {
            setCurrentPlayer(currentPlayer === 'red' ? 'blue' : 'red'); // Switch player
        }
    }, [board]);

    const placePiece = (index) => {
        if (board[index] !== null) return; // Ignore if spot is already taken

        const newBoard = [...board];
        newBoard[index] = currentPlayer;
        setBoard(newBoard);
    };

    return (
        <div style={{ 
            zIndex: '0',
            position: 'fixed', 
            width: '100%', 
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '200px' }}>
            {board.map((color, index) => (
                <DozoPiece
                    key={index}
                    color={color}
                    position={boardCoordinates[index]}
                    onClick={() => placePiece(index)}
                />
            ))}
            {renderWinningLine()}
        </div>
    );
};

export default DozoBoard;

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from './Auth';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  return children
  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;

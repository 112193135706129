import React, { useState, useEffect } from "react";
import Select from 'react-select';
import "../Styles/billing.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Billing = () => {

    const options = [
      { value: '1234', label: '1234' },
      { value: '5678', label: '5678' },
      { value: '0123', label: '0123' },
    ];

    const [selectedValue, setSelectedValue] = useState(null); // State for selected value

    const handleChange = (newValue) => {
      setSelectedValue(newValue);
    };

  return (
    <div className="billingpage">
      <div className="payment">
        <h1> Total Amount </h1>
        <div className="pay">
          <div className="amount"> Rs. {12000} </div>
          <div className="paybutton"> Pay </div>
        </div>
        <hr />
        <div className="paymentfooter">
          <div className="paymentmethod"> Payment Method </div>
          <div className="paymentmethodselected"> VISA </div>
          {/* <div className="selectcard">
            <Select
              value={selectedValue}
              onChange={handleChange}
              options={options}
            />
          </div> */}
        </div>
      </div>
      <div className="invoicecard">
        <div className="invoiceheader">
          <div className="item"> Item </div>
          <div className="quantity"> Quantity </div>
          <div className="amount"> Amount </div>
        </div>
        <div className="innerinvoicecard">
          <div className="itemcard">
            <h1 className="itemid"> ITEM007 </h1>
            <div className="detail">
              <div className="name"> All American Veg Burger </div>
              <div className="quantity"> 2 </div>
              <div className="amount"> 700 </div>
            </div>
            <div className="tax">
              <div className="detail"> @Rs. 350/unit, 5% GST </div>
              <div className="amount"> 37.5 </div>
            </div>
            <hr />
          </div>
          <div className="itemcard">
            <h1 className="itemid"> ITEM007 </h1>
            <div className="detail">
              <div className="name"> All American Veg Burger </div>
              <div className="quantity"> 2 </div>
              <div className="amount"> 700 </div>
            </div>
            <div className="tax">
              <div className="detail"> @Rs. 350/unit, 5% GST </div>
              <div className="amount"> 37.5 </div>
            </div>
            <hr />
          </div>
          <div className="itemcard">
            <h1 className="itemid"> ITEM007 </h1>
            <div className="detail">
              <div className="name"> All American Veg Burger </div>
              <div className="quantity"> 2 </div>
              <div className="amount"> 700 </div>
            </div>
            <div className="tax">
              <div className="detail"> @Rs. 350/unit, 5% GST </div>
              <div className="amount"> 37.5 </div>
            </div>
            <hr />
          </div>
          <div className="itemcard">
            <h1 className="itemid"> ITEM007 </h1>
            <div className="detail">
              <div className="name"> All American Veg Burger </div>
              <div className="quantity"> 2 </div>
              <div className="amount"> 700 </div>
            </div>
            <div className="tax">
              <div className="detail"> @Rs. 350/unit, 5% GST </div>
              <div className="amount"> 37.5 </div>
            </div>
            <hr />
          </div>
          <div className="itemcard">
            <h1 className="itemid"> ITEM007 </h1>
            <div className="detail">
              <div className="name"> All American Veg Burger </div>
              <div className="quantity"> 2 </div>
              <div className="amount"> 700 </div>
            </div>
            <div className="tax">
              <div className="detail"> @Rs. 350/unit, 5% GST </div>
              <div className="amount"> 37.5 </div>
            </div>
            <hr />
          </div>
          <div className="total">
            <div className="total"> Total: </div>
            <div className="amount"> Rs. 12000 </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Billing;

import React, { useContext, useEffect, useState } from "react";
import { WishlistContext } from "../Wishlist/WishlistContext";
import "./wishlist.css";

const Wishlist = () => {
  const { wishlist, removeFromWishlist, resetWishlist } = useContext(WishlistContext);

  const handleRemove = (fooditem_id) => {
    removeFromWishlist(fooditem_id);
  };

  return (
    <div className="wishlistpage">
      <div className="title">
          <h1>Wishlist</h1>
          <hr />
        </div>
      {Object.keys(wishlist).length === 0 ? (
        <div className="empty-wishlist-message">
          <p>Oops!</p>
          <p>Your wishlist is empty.</p>
        </div>
      ) : (
        <div className="wishlist">
          {Object.keys(wishlist).map((id) => {
            const item = wishlist[id];
            return (
              <div key={id} className="itemcard">
                <div className="category">{item.fooditem_category}</div>
                <div className="detail">
                  <img src={item.fooditem_image || "../../Images/default.jpeg"} alt={item.fooditem_name} className="item-image" />
                  <div className="veg"></div>
                  <div className="info">
                    <div className="item">
                      <div className="name">{item.fooditem_name}</div>
                      <div className="unitamount">{item.fooditem_price}</div>
                    </div>
                    <div className="remove" onClick={() => handleRemove(id)}>
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="order">
            <hr />
          </div>
        </div>
      )}
    </div>
  );
};

export default Wishlist;

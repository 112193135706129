import React, { useState, useEffect, useRef } from "react";
import "./wordle.css";

const wordList = [
  "UMAMI",
  "DASHI",
  "WAGYU",
  "SAKE",
  "NORI",
  "MOCHI",
  "RAMEN",
  "SUSHI",
  "TEMPURA",
  "MISO",
];

const randomWord = () => wordList[Math.floor(Math.random() * wordList.length)];

const WordleGame = () => {
  const [solution, setSolution] = useState(randomWord());
  const [guesses, setGuesses] = useState(Array(6).fill(null));
  const [currentGuess, setCurrentGuess] = useState("");
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  const gridRef = useRef(null); // Ref for the grid

  useEffect(() => {
    setSolution(randomWord());
    const focusInput = () => inputRef.current?.focus();

    if (gridRef.current) {
      gridRef.current.addEventListener("click", focusInput);
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("click", focusInput);
      }
    };
  }, []);

  const handleInput = (event) => {
    const value = event.target.value.toUpperCase().replace(/[^A-Z]/g, "");
    if (value.length <= solution.length) {
      setCurrentGuess(value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && currentGuess.length === solution.length) {
      submitGuess();
    }
  };

  const submitGuess = () => {
    const newGuesses = [...guesses];
    const emptyIndex = newGuesses.indexOf(null);

    if (emptyIndex !== -1) {
      newGuesses[emptyIndex] = currentGuess;
      setGuesses(newGuesses);
      setCurrentGuess("");

      if (currentGuess === solution) {
        setTimeout(() => {
          setMessage("Congratulations! You guessed correctly!");
          setGameOver(true);
        }, 200);
      } else if (emptyIndex === guesses.length - 1) {
        setTimeout(() => {
          setMessage(`Game over! The word was ${solution}.`);
          setGameOver(true);
        }, 200);
      }
    }
  };

  const getTileClass = (letter, index) => {
    if (!letter) return "";
    const correct = letter === solution[index];
    const present = !correct && letter && solution.includes(letter);
    return correct ? "correct" : present ? "present" : "absent";
  };

  return (
    <div className="wordle-game">
      <input
        ref={inputRef}
        type="text"
        value={currentGuess}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        maxLength={solution.length}
        className="hidden-input"
        autoComplete="off"
      />
      <div ref={gridRef} className="grid">
        {guesses.map((word, wordIndex) => (
          <div key={wordIndex} className="word">
            {Array.from({ length: solution.length }).map((_, i) => (
              <div
                key={i}
                className={`tile ${word && getTileClass(word[i], i)}`}
              >
                {word
                  ? word[i]
                  : wordIndex === guesses.indexOf(null) && currentGuess[i]}
              </div>
            ))}
          </div>
        ))}
      </div>
      {gameOver && <p className="message">{message}</p>}
    </div>
  );
};

export default WordleGame;

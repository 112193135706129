import { Card, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Style for uniform card height
const cardStyle = {
  height: "250px", // Maintain consistent card height
};

// Style for image to maintain its look
const imageStyle = {
  height: "150px", // Adjust image height
  objectFit: "cover", // Ensure image maintains its aspect ratio
};

const DishCard = ({ dish, onClick }) => (
  <Card
    hoverable
    cover={
      <img
        alt={dish.fooditem_name}
        src={dish.fooditem_image}
        style={imageStyle}
      />
    }
    onClick={() => onClick(dish)} // Trigger the click event with the dish data
    style={cardStyle} // Apply consistent style for card
  >
    <Card.Meta
      title={dish.fooditem_name}
      description={`Rs. ${dish.fooditem_price}`}
    />
  </Card>
);

const AddNewDishCard = ({ onClick }) => (
  <Card
    hoverable
    style={{
      ...cardStyle,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    onClick={onClick} // Handle opening an empty form for adding a new dish
  >
    <PlusOutlined
      style={{
        fontSize: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
    <p>Add New Dish</p>
  </Card>
);

const DishGrid = ({ dishes, onDishClick, onAddNewClick }) => (
  <Row gutter={[16, 24]}>
    {" "}
    {/* Horizontal and vertical gutter */}
    {/* Add New Dish Card */}
    <Col span={6}>
      <AddNewDishCard onClick={onAddNewClick} />
    </Col>
    {/* Existing Dishes */}
    {dishes.map((dish) => (
      <Col span={6} key={dish.id}>
        <DishCard dish={dish} onClick={onDishClick} />{" "}
        {/* Pass click handler */}
      </Col>
    ))}
  </Row>
);

export default DishGrid;

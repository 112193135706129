import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import img1 from "../../Images/pasta.jpg";
import "../Styles/activeorders.css";
import { Reorder, motion } from "framer-motion";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../Utilities/LocalStorage/localStorageUtils";

const ActiveOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  

  useEffect(() => {
    const savedOrders = readFromLocalStorage("active_orders") || [];
    setOrders(savedOrders);
  }, []);

  const navigate = useNavigate();

  return (
    <div className="activeorderspage">
      <motion.div
        className="quote"
        drag="y"
        dragConstraints={{ top: 0, bottom: 0 }}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        animate={{ y: isDragging ? 50 : 0 }} // Adjust the value to control the drag distance
        transition={{ type: "spring", stiffness: 300 }}
      >
        There are so many apple varieties that if you were to eat an apple a
        day, it would take over 27 years to try them all.
      </motion.div>
      <div className="activeorders">
        <div className="title">
          <h1>Active Orders</h1>
          <hr />
        </div>
        <Reorder.Group axis="y" values={orders} onReorder={setOrders}>
          {orders.map((order) => (
            <Reorder.Item key={order.fooditem_id} value={order}>
              <div className="order">
                <img src={img1} alt="" />
                <div className="detail">
                  <div className="header">
                    <div className="category">{order.fooditem_name}</div>
                    <div className="units"> units </div>
                  </div>
                  <div className="info">
                    <div className="veg"> </div>
                    <div className="item">
                      <div className="name">{order.fooditem_name}</div>
                      <div className="units">{order.units}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>
    </div>
  );
};

export default ActiveOrders;

import "./preparationcard.css";
import React from "react";

const IngredientsCard = ({ details }) => {
  return (
    <div className="preparation-card">
      {/* <div className="header"> " </div> */}
      <div className="preparation">
        {details}
      </div>
    </div>
  );
};

export default IngredientsCard;

import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../../Authentication/AxiosInstance";
import "./AuthStyles.css"; // Custom CSS for styling
import logo from "../../../Images/logo.jpeg";

const { Title, Text } = Typography;

const LoginSignupLayout = ({ isLogin }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const url = isLogin ? "/restaurant/login/" : "/restaurant/register/";

    try {
      // For signup, include restaurant name and address
      const data = isLogin
        ? {
            phone_number: values.phone_number,
            password: values.password,
          }
        : {
            restaurant_name: values.restaurant_name,
            restaurant_address: values.restaurant_address,
            phone_number: values.phone_number,
            password: values.password,
          };

      const response = await postRequest(url, data);

      // Save tokens into cookies
      document.cookie = `access=${response.token.access}; path=/`;
      document.cookie = `refresh=${response.token.refresh}; path=/`;

      navigate("/restaurant/admin/dashboard"); // Redirect to restaurant admin dashboard
    } catch (error) {
      setError(error.message || "Invalid credentials, please try again.");
    }
    setLoading(false);
  };

  return (
    <div className="auth-fullscreen-container">
      <div className="auth-card">
        <div className="auth-header">
          <img
            src={logo} // Logo path here
            alt="Company Logo"
            className="auth-logo"
          />
          {/* <div className="auth-title">| {isLogin ? "Login" : "Signup"}</div> */}
        </div>

        <Form
          name={isLogin ? "login" : "signup"}
          onFinish={onFinish}
          layout="vertical"
          className="auth-form" // Added custom class to style form
        >
          {!isLogin && (
            <Form.Item
              name="restaurant_name"
              label="Restaurant Name"
              rules={[
                {
                  required: true,
                  message: "Please input your restaurant name!",
                },
              ]}
            >
              <Input placeholder="Restaurant Name" />
            </Form.Item>
          )}

          {!isLogin && (
            <Form.Item
              name="restaurant_address"
              label="Restaurant Address"
              rules={[
                {
                  required: true,
                  message: "Please input your restaurant address!",
                },
              ]}
            >
              <Input placeholder="Restaurant Address" />
            </Form.Item>
          )}

          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="auth-button-fullscreen"
            loading={loading}
            block
          >
            {isLogin ? "Login" : "Signup"}
          </Button>
          {error && <p className="auth-error">{error}</p>}
        </Form>

        {isLogin ? (
          <div className="auth-navigation">
            <Text>
              Don't have an account?{" "}
              <Link to="/restaurant/register">Sign Up</Link>
            </Text>
            <Link
              to="/restaurant/forgot-password"
              className="auth-forgot-password"
            >
              Forgot Password?
            </Link>
          </div>
        ) : (
          <div className="auth-navigation">
            <Text>
              Already signed up? <Link to="/restaurant/login">Login</Link>
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginSignupLayout;

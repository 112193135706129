// src/Components/TableNavigationView/TableNavigationView.jsx
import React, { useEffect, useContext } from "react";
import "../Styles/tablenavigation.css";
import map_icon from "../Assets/map_icon.png";
import notes_icon from "../Assets/notes_icon.png";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../Restaurantpage/RestaurantContext";
import feedback from "../../Images/feedback.png"
import menu from "../../Images/menu.png"
import trivia from "../../Images/trivia.png"

const NavigationMenuOptions = ({ icon, text, onClick }) => {
  return (
    <div className="restaurant-navigation-option" onClick={onClick}>
      <img src={icon} className="restaurant-navigation-icon" alt={text} />
      <div>{text}</div>
    </div>
  );
};

const TableNavigationView = ({ toggleNavView, tableNumber = 1 }) => {
  const navigate = useNavigate();
  const { scrollToMenu, restaurantDetails } = useContext(RestaurantContext);

  const options = [
    {
      icon: menu,
      text: "Menu",
      onClick: () => scrollToMenu(),
    },
    {
      icon: feedback,
      text: "Feedback",
      onClick: () => navigate("/wordle"),
    },
    {
      icon: trivia,
      text: "Trivia",
      onClick: () => navigate("/dozo"),
    },
    // {
    //   icon: notes_icon,
    //   text: "Suggest a Drink",
    //   onClick: () => navigate("/questionflow"),
    // },
  ];

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = "hidden";
    }

    return () => {
      if (html) {
        html.style.overflow = "auto";
      }
    };
  }, []);

  return (
    <>
      <div
        className="table-navigation"
        onClick={() => toggleNavView()}
      />
      <div className="table-navigation-card">
        <div
          className="details-wrapper"
          style={{
            backgroundImage: `
              linear-gradient(
                0deg,
                #614000 0%,
                rgba(97, 65, 0, 0.066) 100%
              ),
              url(${restaurantDetails.image || "default-image-url.jpeg"})`,
          }}
        >
          <div className="restaurant-table-details">
            <div className="table-details">Table #{tableNumber}</div>
            <div className="restaurant-details">
              <div className="restaurant-name">@ {restaurantDetails.name}</div>
              <div className="restaurant-address">{restaurantDetails.address}</div>
            </div>
          </div>
        </div>
        <div className="restaurant-navigation">
          {options.map((option, index) => (
            <NavigationMenuOptions
              key={index}
              icon={option.icon}
              text={option.text}
              onClick={() => {
                option.onClick();
                toggleNavView();
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default TableNavigationView;
